/** https://web.dev/articles/building/a-theme-switch-component */

import { useState } from "react"
import './theme-toggle.css'
import {ReactComponent as SunMoonIcon} from './sun-moon.svg'

export default function ThemeSwitcher(props) {
    let [isDark, setIsDark] = useState(isInitialThemeDark())

    return <div className={isDark ? 'dark' : 'light'}>
        {props.children}
        <div className='theme-switcher-icon hideprint' onClick={() => {
            localStorage.setItem('is-dark', !isDark)
            setIsDark(!isDark)
        }}>
            <div className='sun-and-moon theme-toggle' data-theme={isDark ? 'dark' : 'light'}>
                <SunMoonIcon/>
            </div>
        </div>
    </div>
}


function isInitialThemeDark() {
    if (localStorage.getItem('is-dark') !== null) {
        return localStorage.getItem('is-dark') === 'true'
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        // Dark mode is preferred by the user's system
        return true
    } else {
        // Light mode is preferred
        return false
    }
}