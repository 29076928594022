import './App.css';
import ThemeSwitcher from './components/theme-switcher';
import data from "./data.json"
import snarkdown from 'snarkdown';
import { ReactComponent as PrintSvg } from './images/printer.svg'

document.body.addEventListener('keypress', (evt)=>{
  if (evt.key === ' '){
    document.body.classList.toggle('no-images')
  }
})

/**
 *
 * @param {*} item
 * @param {*} itemKey
 * @returns {HTMLElement}
 */
function RenderRandomObject(item, itemKey) {
  if (item && Array.isArray(item)) {
    return <ul>{Object.keys(item).map((key, index) => {
      const listKey = itemKey + '_' + key
      return <li key={listKey}>
        {RenderRandomObject(item[key], itemKey + '_' + key)}
      </li>
    }

    )}
    </ul>
  }
  if (item && typeof item === 'object') {
    return Object.keys(item).map((key, index) => {
      let CustomTag = 'span'
      let className = key
      const listKey = itemKey + '_' + key
      if (key && key.indexOf('-') > -1) {
        CustomTag = key.split('-')[0]
        className = key.split('-')[1]
        if (CustomTag === 'a') {
          return <a key={listKey} className={className} href={item[key].href} target='_blank' rel="noreferrer">{item[key].text}</a>
        }
        if (CustomTag === 'imghover') {
          return <ImageHover key={listKey}
          src={item[key].src} hover={item[key].hover}
          title={item[key].title} alt={item[key].alt}></ImageHover>
        }
      }
      if (['link'].indexOf(key) > -1) { return '' }
      return <CustomTag key={listKey} className={className}>{RenderRandomObject(item[key], itemKey + '_' + key)} </CustomTag>
    })
  }
  if (item && typeof item === 'string') {
    return <span dangerouslySetInnerHTML={{ __html: snarkdown(item) }}>{}</span>
  }
}

function ImageHover(props) {
  const src = require('./' + props.src)
  const hover = require('./' + props.hover)

  return <div className="square-logo"
    style={{ backgroundImage: `url(${src})` }}
    onMouseEnter={(evt) => {
      evt.target.style.backgroundImage = `url(${hover})`
    }}
    onMouseLeave={(evt) => evt.target.style.backgroundImage = `url(${src})`}
    title={props.title}
  >
    <img src={src} className='print-image' alt={props.alt||props.title} />
  </div>
}


function App() {
  return (
    <ThemeSwitcher>
      <div className='center-container'>
        {RenderRandomObject(data, '')}
      </div>
      <a className='printer' href="download">
        <PrintSvg/>
      </a>
    </ThemeSwitcher>
  );
}

export default App;
